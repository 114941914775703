import { ChipSelect, GeckoPrimerInputError, ProgressIndicatorStep } from "../events";

export const showFormInputError = (element, message) => {
  element.dispatchEvent(GeckoPrimerInputError(true, message));
};

export const hideFormInputError = (element) => {
  element.dispatchEvent(GeckoPrimerInputError(false));
};

export const toggleFormInputError = (show, element, message) => {
  show ? showFormInputError(element, message) : hideFormInputError(element);
};

export const progressIndicatorStep = (element, next = true) => {
  element.dispatchEvent(ProgressIndicatorStep(next));
};

export const chipSelect = (element, identifier) => {
  element.dispatchEvent(ChipSelect(identifier))
}
