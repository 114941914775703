import {
  ANALYTICS_USER_LOGGED_IN,
  MIXPANEL_DATA_PROPERTY_KEYS,
  MIXPANEL_USER_PROPERTY_KEYS,
  ONE_OFF_TRACK_PAGEVIEW_ON_LOGIN,
  WHITELISTED_UTM_QUERY_PARAMS,
} from "./mixpanel_config";
import mixpanel from "mixpanel-browser";
import { readConsentConfig } from "./util";
import { PERF_COOKIE } from "./config";

export function identifyUser(data) {
  const mixpanelUserProperties = _pluckMixpanelUserProperties(data);

  try {
    mixpanel.identify(mixpanelUserProperties.uuid);
    mixpanel.people.set(_formatUserProperties(mixpanelUserProperties));
  } catch (e) {
    if (e instanceof TypeError) {
      // TODO: Add observability for this error
    }
  }
}

function _pluckMixpanelUserProperties(loggedInUserData) {
  const currentUserData = loggedInUserData.current_user;

  const currentUserObj = MIXPANEL_USER_PROPERTY_KEYS.reduce((acc, cur) => Object.assign(acc, { [cur]: currentUserData[cur] }), {});
  const dataObj = MIXPANEL_DATA_PROPERTY_KEYS.reduce((acc, cur) => Object.assign(acc, { [cur]: loggedInUserData[cur] }), {});

  return Object.assign(currentUserObj, dataObj);
}

function _formatUserProperties(userPropertiesCookie) {
  delete Object.assign(userPropertiesCookie, { ["$email"]: userPropertiesCookie["email"] })["email"];
  delete Object.assign(userPropertiesCookie, { ["$name"]: userPropertiesCookie["name"] })["name"];

  userPropertiesCookie['sign_up_source'] = _mapSignUpSource(userPropertiesCookie['sign_up_source'])

  return userPropertiesCookie;
}

export function untrackUser() {
  localStorage.removeItem(ONE_OFF_TRACK_PAGEVIEW_ON_LOGIN)

  try {
    mixpanel.reset()
  } catch (e) {
    if (e instanceof TypeError) {
      // TODO: Add observability for this error
    }
  }
}

export function trackLoggedOutPageviews(currentUser) {
  if (currentUser.current_user != null) {
    return;
  }

  trackLoggedOutPage()
}

export function trackLoggedOutPage() {
  const trackPage = document.querySelector('[data-analytics-track-nonloggedin-pageview="true"]')

  if (trackPage != null) {
    trackPageview()
  }
}

export function trackPageviewAfterConsentGiven(currentUser) {
  if (currentUser.current_user != null) {
    trackPageview()
  } else {
    trackLoggedOutPage()
  }
}

export function setMixpanelDeviceIdOnHiddenInputs(mixpanelDeviceId) {
  const hiddenInputElements = document.querySelectorAll('[id=user_mixpanel_device_id]')

  hiddenInputElements.forEach(element => {
    element.value = mixpanelDeviceId
  })
}

export function trackPageview() {
  try {
    mixpanel.track_pageview()
  } catch (e) {
    if (e instanceof TypeError) {
      // TODO: Add observability for this error
    }
  }
}

export function optOutAnalytics() {
  try {
    mixpanel.opt_out_tracking();
  } catch (e) {
    if (e instanceof TypeError) {
      // TODO: Add observability for this error
    }
  }
}

export function trackPageviewOnFirstLogin(currentUser) {
  if (currentUser.current_user !== null && localStorage.getItem(ONE_OFF_TRACK_PAGEVIEW_ON_LOGIN) === null) {
    trackPageview()
    localStorage.setItem(ONE_OFF_TRACK_PAGEVIEW_ON_LOGIN, "true");
  }
}

export function optInMixpanel() {
  if (!mixpanel.has_opted_in_tracking()) {
    mixpanel.opt_in_tracking();
  }
}

export function optInOrOutOfTracking() {
  try {
    if (readConsentConfig(PERF_COOKIE)) {
      this.optInMixpanel();
    } else {
      this.optOutAnalytics()
    }
  } catch (e) {
    if (e instanceof TypeError) {
      // TODO: Add observability for this error
    }
  }
}

// used for tracking non-auth related events
export function trackEvent(eventName, eventProperties) {
  try {
    if (_isUserLoggedIn() || _isTrackedLoggedOutPage()) {
      Mixpanel.track(eventName, eventProperties)
    }
  } catch (e) {
    if (e instanceof TypeError) {
      // TODO: Add observability for this error
    }
  }
}

export function unconditionalTrackEvent(eventName, eventProperties) {
  try {
    Mixpanel.track(eventName, eventProperties)
  } catch (e) {
    if (e instanceof TypeError) {
      // TODO: Add observability for this error
    }
  }
}

export function searchCategoryEventProperties(type, itemText = null, isTrending = false) {
  let searchCategoryValue = _generateSearchTypeValue(isTrending, type)

  let searchCategory = { 'search_category': searchCategoryValue }

  let name = _parseSearchTypeToName(type, itemText)

  return {
    ...searchCategory,
    ...name
  }
}

export function setUTMOnHiddenInputs() {
  const hiddenInputElements = document.querySelectorAll('[id=user_utm_json]')

  const urlParams = new URLSearchParams(window.location.search);

  let utmParamsJson = {}

  WHITELISTED_UTM_QUERY_PARAMS.forEach(whitelistedParam => {
    if (urlParams.get(whitelistedParam)) {
      utmParamsJson[whitelistedParam] = urlParams.get(whitelistedParam)
    }
  });

  hiddenInputElements.forEach(element => {
    element.value = JSON.stringify(utmParamsJson)
  })
}
export function processTabIdToEventProp(tabId) {
  let parts = tabId.split('-');
  parts.shift();

  return parts.join('-').replace(/-/g, '_');
}

function _isUserLoggedIn() {
  return localStorage.getItem(ANALYTICS_USER_LOGGED_IN) === "true"
}

function _isTrackedLoggedOutPage() {
  const trackPage = document.querySelector('[data-analytics-track-nonloggedin-pageview="true"]')
  return trackPage != null
}

function _generateSearchTypeValue(isTrending, type) {
  const prefix = isTrending ? "trending" : "search";

  if (type) {
    return `${prefix}_${type.toLowerCase()}`
  } else {
    return `${prefix}_unidentified`
  }
}

function _parseSearchTypeToName(type, itemText) {
  const searchTypeObj = {}

  if (type && itemText) {
    searchTypeObj[`${type.toLowerCase()}_name`] = itemText
  }

  return searchTypeObj
}

function _mapSignUpSource(signUpSource) {
  switch (signUpSource) {
    case null:
      return "email-web";
    case "api":
      return "email-app";
    case "google_oauth2-api":
      return "google-app";
    case "apple-api":
      return "apple-app";
    case "google_oauth2-web":
      return "google-web";
    default:
      return signUpSource;
  }
}
