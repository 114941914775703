export default () => {
  Alpine.data("chip", (multiSelect = false) => ({
    multiSelect: multiSelect,
    activeChip: null,

    init() {
      this.multiSelect = this.$root.dataset.multiSelect === "true";
      this.activeChip = this.$root.dataset.activeChip;
    },

    handleSelect(e) {
      const selectedChip = e.currentTarget;

      if (!this.multiSelect) {
        this.activeChip = selectedChip.dataset.identifier;
        return;
      }

      selectedChip.setAttribute("x-on:click", "handleDeselect");
      selectedChip.classList.add("selected");

      const chipText = selectedChip.querySelector(".gecko-chip-text");
      chipText.classList.add("selected");

      const chipIconContainer = selectedChip.querySelector(".gecko-chip-icon");
      this._addDeselectIcon(chipIconContainer);
    },

    handleDeselect(e) {
      const selectedChip = e.currentTarget;

      selectedChip.setAttribute("x-on:click", "handleSelect");
      selectedChip.classList.remove("selected");

      const chipText = selectedChip.querySelector(".gecko-chip-text");
      chipText.classList.remove("selected");

      const chipIconContainer = selectedChip.querySelector(".gecko-chip-icon");
      this._removeDeselectIcon(chipIconContainer);
    },

    // Triggered by "gp-chip-select" event
    handleSelectEvent(e) {
      this.activeChip = e.detail.identifier;
    },

    _addDeselectIcon(container) {
      const icon = document.createElement("i");
      icon.classList.add("fa", "fa-plus", "tw-rotate-45", "selected");
      container.appendChild(icon);
    },

    _removeDeselectIcon(container) {
      const node = container.querySelector("i");
      container.removeChild(node);
    },
  }));
}
