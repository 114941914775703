import { Controller } from "stimulus";
import { createPopper } from '@popperjs/core';
import { buildTooltip } from "../../template/tooltip_templates";
import { EventTooltipLoaded } from "../../events";

export default class extends Controller {
  connect() {
    // Defer loading of dynamic tooltips to prevent heavy initial page load.
    setTimeout(() => {
      document.querySelectorAll("*[data-tooltip]").forEach(this._addEventListeners.bind(this));
      window.addEventListener(EventTooltipLoaded, (e) => {
        e.target.querySelectorAll("*[data-tooltip]").forEach(this._addEventListeners.bind(this));
      });
    }, 200);
  }

  _addEventListeners(element) {
    element.addEventListener("mouseenter", (e) => { this._renderTooltip(e.currentTarget) });
    element.addEventListener("mouseleave", (e) => { this._removeTooltip(e.currentTarget) });

    if (element.dataset.tooltipToggle === "true") {
      element.addEventListener("click", (e) => { this._removeTooltip(e.currentTarget) });
    }
  }

  _renderTooltip(element) {
    this._removeTooltip(element);

    let tooltipContent = element.dataset.tooltip;
    if (!tooltipContent) {
      return;
    }

    let tooltipType = element.dataset.tooltipType || "short";
    let tooltipClasses = element.dataset.tooltipClasses || "";
    let tooltipTemplate = buildTooltip(tooltipContent, tooltipType, tooltipClasses);
    let tooltipPlacement = element.dataset.tooltipPlacement || "top";

    element.insertAdjacentHTML("beforeend", tooltipTemplate);
    let tooltipTarget = element.querySelector("[data-gecko-tooltip='true']");

    this.popperInstance = createPopper(element, tooltipTarget, {
      placement: tooltipPlacement,
      modifiers: [
        {
          name: "offset",
          options: {
            offset: [0, 4],
          },
        },
      ],
    });
  }

  _removeTooltip(element) {
    let tooltipTarget = element.querySelector("[data-gecko-tooltip='true']");
    tooltipTarget?.remove();

    this.popperInstance?.destroy();
  }
}
