const baseTooltipTemplate = (content, type, classes) => `
<div role="tooltip" data-gecko-tooltip="true" class="gecko-tooltip gecko-tooltip-${type} gecko-override-links
 gecko-tooltip-extra-padding ${classes}">
  <span class="gecko-tooltip-content">${content}</span>
  <div data-popper-arrow></div>
</div>
`;

const VALID_TOOLTIP_TYPES = ["long", "simple", "short"];
export const buildTooltip = (content, type = "short", classes = "") => {
  if (!VALID_TOOLTIP_TYPES.includes(type)) {
    type = "short";
  }

  return baseTooltipTemplate(content, type, classes);
};
