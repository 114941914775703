import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["checkbox", "radio", "submitForm"]

  checkout(e) {
    const radioChecked = this.radioTargets.find(radio => radio.checked);

    if (this.checkboxTarget.checked && radioChecked) {
      this._appendIdToCheckoutUrl(radioChecked.value);
      Modal.show("stripe_billing_form_modal");
    } else {
      alert("Please accept the Fast Pass Terms or select the request to submit the form.");
    }
  }

  _appendIdToCheckoutUrl(value) {
    let url = this.submitFormTarget.dataset.stripeCheckoutPaymentsUrlValue;
    let params = new URLSearchParams({ id: value });
    url += `&${params.toString()}`;
    this.submitFormTarget.dataset.stripeCheckoutPaymentsUrlValue = url
  }
}
