export const MIXPANEL_USER_PROPERTY_KEYS = ['uuid', 'name', 'email', 'sign_up_source', 'sign_up_locale', 'has_api_access', 'created_at'];
export const MIXPANEL_DATA_PROPERTY_KEYS = ['has_subscription', 'has_enterprise_api_subscription']

export const WHITELISTED_UTM_QUERY_PARAMS = ['utm_source', 'utm_medium', 'utm_campaign', 'utm_content', 'utm_term']

export const ONE_OFF_TRACK_PAGEVIEW_ON_LOGIN = "one-off-track-pageview-on-login"

export const SELECT_SIGN_IN_CTA_EVENT = "select_sign_in_cta"
export const SELECT_SEARCH_CTA_EVENT = "select_search_cta"
export const SEARCH_EVENT = "search"
export const SELECT_SEARCH_EVENT = "select_search_result"
export const ANALYTICS_USER_LOGGED_IN = "analytics_user_logged_in"
export const SELECT_SEARCH_HIGHLIGHTS = "select_search_highlights"
export const SELECT_COIN_MAIN_TAB = "select_coin_main_tab"
export const SELECT_COIN_INFO = "select_coin_info"
export const VIEW_COIN_INSIGHTS = "view_coin_insights"
export const SELECT_COIN_SENTIMENT_VOTE_EVENT = "select_coin_sentiment_vote"

export const SEARCH_EVENT_ORIGIN = {
  NAVBAR: "navbar",
  KEYBOARD_SHORTCUT: "keyboard_shortcut",
  STICKY_CTA: "sticky_cta",
};
