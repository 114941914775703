import { Controller } from "stimulus";
import { trackEvent, unconditionalTrackEvent, processTabIdToEventProp } from "../../analytics";
import { SELECT_COIN_INFO, SELECT_COIN_MAIN_TAB } from "../../mixpanel_config";

export default class extends Controller {

  connect() {
    this.activeButtonIdentifers = {}
  }

  trackEvent(e) {
    trackEvent(e?.currentTarget?.dataset?.analyticsEvent, this._getEventAttributes(e))
  }

  unconditionalTrackEvent(e) {
    unconditionalTrackEvent(e?.currentTarget?.dataset?.analyticsEvent, this._getEventAttributes(e))
  }

  tabTrackEvent(e) {
    const analyticsEvent = e.detail?.currentTarget?.dataset?.analyticsEvent

    if (Object.keys(this.activeButtonIdentifers).length === 0 && e.currentTarget.dataset.activeButton === e.currentTarget.id) {
      return
    }

    if (this.activeButtonIdentifers[analyticsEvent] === e.currentTarget.id) {
      return
    }

    this.activeButtonIdentifers[analyticsEvent] = e.currentTarget.id

    const tabName = processTabIdToEventProp(e.currentTarget.id)
    unconditionalTrackEvent(e?.currentTarget?.dataset?.analyticsEvent, { tab_name: tabName })
  }

  coinInfoTrackEvent(e) {
    const interactionType = e.currentTarget.dataset.interactionType;
    const infoType = e.currentTarget.dataset.infoType;
    const infoValue = e.currentTarget.dataset.infoValue;

    unconditionalTrackEvent(SELECT_COIN_INFO, { interaction_type: interactionType, info_type: infoType, info_value: infoValue })
  }

  oneTimeTrackEvent(e) {
    if (e.currentTarget.dataset.eventHasFired !== "true") {
      e.currentTarget.dataset.eventHasFired = "true";
      unconditionalTrackEvent(e.currentTarget.dataset?.analyticsEvent, this._getEventAttributes(e))
    }
  }

  _getEventAttributes(e) {
    if (e?.currentTarget?.dataset?.analyticsEventProperties === undefined) {
      return {}
    }

    return JSON.parse(e?.currentTarget?.dataset?.analyticsEventProperties)
  }
}
